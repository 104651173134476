import React, { useEffect, useState } from "react";
import logo from '../images/tech4mation logo-02 1.svg';
import { Link, useLocation } from "react-router-dom";

const Header = () =>{
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    useEffect(() => {
        // Update the active link when the path changes
        setActiveLink(location.pathname);
    }, [location]);

    return(
        <div className="header">
            <Link to="/"><img src={logo} alt="" /></Link>

            <nav>
                <ul>
                    <li>
                        <Link to="/"className={activeLink === "/" ? "active-link" : ""}>
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link to="/about" className={activeLink === "/about" ? "active-link" : ""}>
                            about us
                        </Link>
                    </li>
                    <li>
                        <Link to="/service" className={activeLink === "/service" ? "active-link" : ""}>
                            OUR SERVICES
                        </Link>
                    </li>
                    <li>
                        <Link to="/contact" className={activeLink === "/contact" ? "active-link" : ""}>
                            CONTACT US
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default Header;